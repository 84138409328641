
.card__body {
  padding: 1rem;
  background: #f3f3f3;
  border: 1px solid #cccccc;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.card__title {
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 800;
  line-height: 19px;
  margin-bottom: 0.5rem;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 44px;
  color: #333333;
  margin: 0;
}

a {
  font-size: 0.85rem;
  text-decoration: underline;
}

.in-between {
  margin-bottom: 2rem;
}
